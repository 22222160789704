
.alertText{
    font-weight: 500;
}
.alertTitle{
    margin-bottom:0rem !important;
}
.navbar{
height:60px !important;
}
.bg{
    background-image: url(../assets/bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.restaurantName{
    margin-left:10px;
}
.checkout-card .card-header{
    background-color: #162A3E !important;
    color:white !important;
    border-radius:8px 8px 0px 0px !important;
}