html,
body {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
}
.plu-css ul:hover {
  overflow-y: hidden;
}
/* .plu-css{
  background:url("../../src/assets/bgimage.png");
  background-repeat: repeat;
  background-position: center;
  background-size: contain;
} */
.plu-css .badge {
  position: initial !important;
}

/* .plu-css .card{
  height: 450px;
} */
.plu-css .card-body {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.productImage{
  display:flex;
  justify-content: center;
  margin:auto;
  /* padding:20px; */
}
.thumbImage{
  width:130px !important;
  height:130px !important; 
}

#dmenu{
    background-color: transparent !important;
    text-align: center !important;
}
.addToCart{
    background-color:#1B2F44 !important;
    color:white !important;
}
.modalImage{
  width:200px;
}
.modalWrap{
  padding:20px;
  text-align:center;
}
#dmenu ul.CategoryListing{
  overflow: hidden !important;
  background-color: #dedede;
  height: auto !important;
  display:block;
}
#dmenu ul:hover{
  padding: 0 !important;
}
  
@media screen and (min-device-width: 1600px) and (max-device-width: 2560px){
  #dmenu ul.CategoryListing{
      margin-top:35px;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 567px) {
  .tableMenu{
    display:block !important;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 812px) {
  .checkboxList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .checkboxList li{
    width: 100px;
  }
  .cardBorder{
    border-bottom: 2px solid #1B2F44;
  }
  
}

.addToCartForTable {
  margin-top: 10px;
  background-color: rgb(33, 198, 81)!important;
  color: #fff!important;
  border-color: rgb(33, 198, 81) !important;
  padding: 10px 0;
}