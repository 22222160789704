body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tableContainer {
  height: 100vh;
  /* background: url("../src/assets/main-bg\ 1.png"); */
  /* background-image: url("./assets/bg-elements.png"); */
  /* background-image: url("./assets//main-bg.jpg"); */
  /* background-size: cover; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableContainer .card{
  width: 100%;
  padding: 45px 40px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  border-radius: 20px;
}

.salesStyle a, .salesStyle span{
 color:#000 !important;
 font-weight:600 !important;
}
.salesTable .ant-pagination{
  display: none;
}
.btn-primary,
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: rgb(33, 198, 81) !important;
    border-color: rgb(33, 198, 81) !important;
}
.cartDescription .tableOrderButtonVipps,.cartDescription .tableOrderButton {
  width: 200px !important;
}
.ant-modal.reserveProductModal, .reserveProductModal .ant-modal-header{
  border-radius: 10px !important;
 }